import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { actionTypes } from "../../store/storeTypes";
import { calculateAspectRatioFit } from "../../components/utils";

const imageStyle = {
  pointerEvents: "none",
};

const MAXWIDTH = 80;
const MAXHEIGHT = 80;
const IMAGE_MARGIN = 6;

export const Thumbnails = ({ dimensions, spaceRow }) => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const { spaceImageCacheLoaded, selectedSpaceImage } = store;

  if (dimensions) {
    const cols = Math.ceil(
      (Object.keys(spaceRow.images).length * (IMAGE_MARGIN * 2 + MAXHEIGHT)) /
        dimensions.height
    );
    const containerStyle = {
      height: `${dimensions.height}px`,
      width: `${cols * (IMAGE_MARGIN * 2 + MAXWIDTH)}px`,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      boxSizing: "border-box",
    };

    return spaceImageCacheLoaded &&
      spaceRow.id in grefs.spaceImageCache.current ? (
      <div style={containerStyle}>
        {Object.entries(spaceRow.images)
          .sort((a, b) => {
            return a[1].index > b[1].index
              ? 1
              : b[1].index > a[1].index
              ? -1
              : 0;
          })
          .map((item, i) => {
            const smallSize = calculateAspectRatioFit(
              item[1].dimensions.width,
              item[1].dimensions.height,
              MAXWIDTH,
              MAXHEIGHT
            );
            return (
              <div
                key={"thumnail_" + i}
                style={{
                  position: "relative",
                  width: smallSize.width,
                  height: smallSize.height,
                  // border: "1px solid green",
                  margin: IMAGE_MARGIN,
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  title="Select this image"
                  onClick={() =>
                    dispatch({
                      type: actionTypes.SET_SELECTED_SPACE_IMAGE,
                      payload: {
                        ...selectedSpaceImage,
                        [spaceRow.id]: item[0],
                      },
                    })
                  }
                >
                  <img
                    style={{
                      ...imageStyle,
                      width: smallSize.width,
                      height: smallSize.height,
                    }}
                    alt="alt"
                    src={
                      grefs.spaceImageCache.current[spaceRow.id][item[0]].image
                    }
                  ></img>
                </div>
              </div>
            );
          })}
      </div>
    ) : null;
  } else return null;
};
