import gif from "../images/hug.gif";

export const Loader = ({ width }) => {
  return (
    <div
      style={{
        width: "100%",
        height:"100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexGrow: 1,
        // flexShrink: 0,
        // flexBasis: "auto",
      }}
    >
      <img
        src={gif}
        alt="loading..."
        style={{ width: width, height: width }}
      ></img>
    </div>
  );
};
