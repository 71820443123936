import { FullSize } from "./FullSize";
import { Caption } from "./Caption";

export const ImageWithCaption = ({ spaceRow }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <Caption spaceRow={spaceRow} />
      <FullSize spaceRow={spaceRow} />
    </div>
  );
};
