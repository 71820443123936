const apiurl = "https://zq0zxcyfl6.execute-api.us-east-1.amazonaws.com/prod";

export const getDynamoDBData = async (auth, endpoint, user, id = "") => {
  const url = `${apiurl}/${endpoint}?user=${user}&id=${id}`;
  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function editDynamoDBData(auth, endpoint, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/${endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const deleteDynamoDBData = async (auth, endpoint, user, id) => {
  const url = `${apiurl}/${endpoint}?user=${user}&id=${id}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "Delete",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function saveDocToS3(auth, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/images`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const getSpaceImage = async (auth, filepath, type) => {
  const signedUrl = await getFileDownloadUrl(auth, filepath);

  const response = await fetch(signedUrl, {
    method: "GET",
  });

  return response.blob().then((response) => {
    const blob = response.slice(0, response.size, type);
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  });
};

export async function getFileDownloadUrl(auth, filepath) {
  try {
    const token = await auth.getToken();
    const downloadUrl = await fetchDownloadUrl(token, filepath);
    return downloadUrl.body;
  } catch (error) {
    console.error("Could not get file URL", error);
    throw error;
  }
}

async function fetchDownloadUrl(token, filename) {
  const response = await fetch(`${apiurl}/images/signedurl`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      file: filename,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

// export const getCognitoGroups = async (auth, user) => {
//   const url = `${apiurl}/security/groups?user=${user}`;

//   const encoded = encodeURI(url);
//   const token = await auth.getToken();
//   const response = await fetch(encoded, {
//     method: "GET",
//     headers: {
//       Authorization: token,
//     },
//   });
//   return response.json().then((response) => {
//     if (response.body?.errorMessage) {
//       throw Error(response.body.errorMessage);
//     }
//     return response;
//   });
// };

export const getDynamoDBConfig = async (auth, user) => {
  const url = `${apiurl}/config?user=${user}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function editDynamoDBConfig(auth, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/config`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

// export const getUser = async (user) => {
//   const url = `${apiurl}/user?user=${user}`;
//   const encoded = encodeURI(url);
//   const response = await fetch(encoded, {
//     method: "GET",
//   });
//   return response.json().then((response) => {
//     if (response.body?.errorMessage) {
//       throw Error(response.body.errorMessage);
//     }
//     return response;
//   });
// };

export const getSite = async (user) => {
  const url = `${apiurl}/site?user=${user}`;
  const encoded = encodeURI(url);
  const response = await fetch(encoded, {
    method: "GET",
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export const getCandidate = async (auth, id) => {
  const url = `${apiurl}/candidate?id=${id}`;
  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function addCandidate(payload) {
  const response = await fetch(`${apiurl}/candidate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}
export const deleteCandidate = async (auth, user, id) => {
  const url = `${apiurl}/candidate?user=${user}&id=${id}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "Delete",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export const getCustomer = async (auth, id) => {
  const url = `${apiurl}/customer?id=${id}`;
  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function addCognitoUser(auth, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/cognito`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function postMySQLData(auth, endpoint, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/${endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const deleteBooking = async (auth, user, id, eventId) => {
  const url = `${apiurl}/booking?user=${user}&id=${id}&eventId=${eventId}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "Delete",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export const listBookingAnon = async (auth, user, anon) => {
  const url = `${apiurl}/bookings/anon?user=${user}&anon=${anon}`;
  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};
