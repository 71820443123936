import React, { useState, useContext } from "react";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { Loader } from "../../components/Loader";
import { saveDocToS3 } from "../../api/api";
import { arrayBufferToBase64 } from "./utils";
import { actionTypes } from "../../store/storeTypes";

const labelStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: 12,
  fontWeight: 400,
  color: "black",
  backgroundColor: "#fffff",
  borderRadius: 8,
  paddingLeft: 8,
  paddingRight: 8,
  border: "1px solid black",
  height: "100%",
  cursor: "pointer",
};

export const SingleFileUploader = () => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const { auth, config, identity, spaceRow, selectedSpaceImage } = store;
  const [status, setStatus] = useState("initial");

  const handleFileChange = (e) => {
    if (e.target.files) {
      setStatus("initial");
      // setFile(e.target.files[0]);
      const file = e.target.files[0];
      if (file) {
        if (file.uploaded) {
          alert("this file has already been uploaded");
        } else {
          setStatus("uploading");
          const reader = new window.FileReader();
          reader.readAsArrayBuffer(file);
          reader.onloadend = () => {
            const image = arrayBufferToBase64(reader.result);

            const payload = {
              name: file.name,
              folder: identity.user + "/" + spaceRow.id,
              image: image,
            };

            const dimensions = {};

            const addToImageCache = () => {
              const newSpaceImages =
                spaceRow.id in grefs.spaceImageCache.current
                  ? grefs.spaceImageCache.current[spaceRow.id]
                  : {};

              const imageUrl = URL.createObjectURL(file);
              const img = new Image();
              img.src = imageUrl;
              img.onload = () => {
                dimensions.width = img.width;
                dimensions.height = img.height;
                newSpaceImages[file.name] = {
                  image: imageUrl,
                  dimensions: dimensions,
                };
                grefs.spaceImageCache.current = {
                  ...grefs.spaceImageCache.current,
                  [spaceRow.id]: newSpaceImages,
                };
              };
            };
            addToImageCache();

            const asyncfunction = async () => {
              const result = await saveDocToS3(auth, payload);
              if (result.statusCode && result.statusCode === 201) {
                // update SpacesTable
                const newspaceRow = { ...spaceRow };
                if ("images" in newspaceRow) {
                  newspaceRow.images[file.name] = {
                    folder: identity.user + "/" + spaceRow.id + "/" + file.name,
                    dimensions: dimensions,
                    filetype: file.type,
                    caption: "",
                    index: Object.keys(newspaceRow.images).length,
                  };
                } else {
                  newspaceRow["images"] = {
                    [file.name]: {
                      folder:
                        identity.user + "/" + spaceRow.id + "/" + file.name,
                      dimensions: dimensions,
                      filetype: file.type,
                      caption: "",
                      index: 0,
                    },
                  };
                }
                dispatch({
                  type: actionTypes.SET_SPACE_ROW,
                  payload: newspaceRow,
                });
                dispatch({
                  type: actionTypes.SET_SELECTED_SPACE_IMAGE,
                  payload: {
                    ...selectedSpaceImage,
                    [spaceRow.id]: file.name,
                  },
                });
                setStatus("success");
              } else {
                console.log("result", result);
                setStatus("fail");
              }
            };
            asyncfunction();
          };
        }
      }
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        paddingTop: 0,
        height: 30,
        // border:"1px solid blue",
      }}
    >
      <div style={{ paddingTop: 10 }}>
        <label htmlFor={`file_x`} style={labelStyle}>
          {config.translations.chooseImageToUpload}
          {status === "uploading" && (
            <div style={{ marginLeft: 10 }}>
              <Loader width={16} />
            </div>
          )}
        </label>
        <input
          id={`file_x`}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};
