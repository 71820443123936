import { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { getSpaceImage } from "../../api/api";
import { calculateAspectRatioFit } from "../../components/utils";
import { Loader } from "../../components/Loader";
import { bookThisSpace } from "./functions";

const MAXWIDTH = 200;
const MAXHEIGHT = 140;

const containerstyle = {
  height: MAXHEIGHT,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  boxSizing: "border-box",
  marginTop: 10,
};

export const SpaceCard = ({ id }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, config, spaces } = store;
  const grefs = useContext(GlobalRefContext);

  const [dimensions, setDimensions] = useState();
  const [imagesrc, setImagesrc] = useState();
  const [noDefaultImage, setNoDefaultImage] = useState(false);
  const [localRow, setLocalRow] = useState();

  useEffect(() => {
    const spaceRow = spaces.find((item) => {
      return item.id === id;
    });
    setLocalRow(spaceRow);
    if (
      spaceRow &&
      spaceRow.images &&
      Object.keys(spaceRow.images).length > 0
    ) {
      if (spaceRow.id in grefs.spaceImageCache.current) {
        //do nothing
      } else {
        //add it
        grefs.spaceImageCache.current = {
          ...grefs.spaceImageCache.current,
          [spaceRow.id]: {},
        };
      }

      //get first image for space
      const firstImage = Object.entries(spaceRow.images).find((image) => {
        return +image[1].index === 0;
      });
      if (firstImage) {
        if (firstImage[0] in grefs.spaceImageCache.current[spaceRow.id]) {
          const smallSize = calculateAspectRatioFit(
            firstImage[1].dimensions.width,
            firstImage[1].dimensions.height,
            MAXWIDTH,
            MAXHEIGHT
          );
          setDimensions(smallSize);
          setImagesrc(
            grefs.spaceImageCache.current[spaceRow.id][firstImage[0]].image
          );
        } else {
          const imagefolder = firstImage[1].folder;
          const dimensions = firstImage[1].dimensions;
          const asyncfunction = async () => {
            const filetype = firstImage[1].filetype.split("/")[1];
            const result = await getSpaceImage(auth, imagefolder, filetype);
            const newSpaceImage = {
              image: result,
              dimensions: dimensions,
            };
            grefs.spaceImageCache.current = {
              ...grefs.spaceImageCache.current,
              [spaceRow.id]: {
                ...grefs.spaceImageCache.current[spaceRow.id],
                [firstImage[0]]: newSpaceImage,
              },
            };
            const smallSize = calculateAspectRatioFit(
              firstImage[1].dimensions.width,
              firstImage[1].dimensions.height,
              MAXWIDTH,
              MAXHEIGHT
            );
            setDimensions(smallSize);
            setImagesrc(newSpaceImage.image);
          };
          asyncfunction();
        }
      } else {
        setNoDefaultImage(true);
      }
    } else {
      setNoDefaultImage(true);
    }
  }, [auth, id, spaces, grefs]);

  return (
    <div style={containerstyle}>
      <div
        style={{
          width: MAXWIDTH,
          height: MAXHEIGHT,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        {dimensions && imagesrc ? (
          <img
            style={{
              width: dimensions.width,
              height: dimensions.height,
            }}
            alt="alt"
            src={imagesrc}
          ></img>
        ) : !noDefaultImage ? (
          <Loader width={30} />
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={() =>
            dispatch({
              type: actionTypes.SET_SHOW_ALL_IMAGES,
              payload: { active: true, id: id },
            })
          }
        >
          SHOW ALL IMAGES
        </Button>
        <Button
          onClick={() => bookThisSpace(dispatch, actionTypes, id)}
          style={{
            border: "1px solid  black",
            borderRadius: 8,
            backgroundColor: "brown",
            color: "white",
            width: "260px",
          }}
        >
          BOOK THIS SPACE
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          paddingRight: 20,
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>{id}</h3>
          <div>{`${config.translations.cost} ${
            config.translations.currencySymbol
          } ${localRow ? localRow.cost : 0} ${
            config.translations.costPerHour
          }`}</div>
        </div>
        <div
          style={{
            fontSize: 12,
          }}
        >
          {localRow && localRow.description ? localRow.description : ""}
        </div>
      </div>
    </div>
  );
};
