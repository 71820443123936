import { useContext, useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

const containerStyle = {
  display: "flex",
  paddingTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
};
export const Caption = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { selectedSpaceImage, spaceRow } = store;
  const [caption, setCaption] = useState("");

  useEffect(() => {
    if (
      spaceRow.id in selectedSpaceImage &&
      selectedSpaceImage[spaceRow.id] in spaceRow.images
    ) {
      setCaption(spaceRow.images[selectedSpaceImage[spaceRow.id]].caption);
    } else {
      setCaption("");
    }
  }, [spaceRow, selectedSpaceImage]);

  const onSave = () => {
    const newImages = {
      ...spaceRow.images,
      [selectedSpaceImage[spaceRow.id]]: {
        ...spaceRow.images[selectedSpaceImage[spaceRow.id]],
        caption: caption,
      },
    };
    dispatch({
      type: actionTypes.SET_SPACE_ROW,
      payload: { ...spaceRow, images: newImages },
    });
  };
  return (
    <div style={containerStyle}>
      <TextField
        style={{ width: "100%" }}
        label="Caption"
        value={caption}
        onChange={(e) => setCaption(e.target.value)}
      />
      <Button style={{ width: "60px" }} onClick={() => onSave()}>
        Save
      </Button>
    </div>
  );
};
