import { useContext } from "react";
import { Button } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

const palette = {
  red: "#ff0000",
  blue: "#0000ff",
  green: "#00ff00",
  yellow: "yellow",
  cyan: "cyan",
  // lime: "lime",
  gray: "gray",
  orange: "orange",
  purple: "purple",
  // black: "black",
  // white: "white",
  pink: "pink",
  darkblue: "darkblue",
};

const containerStyle = { display: "flex", margin: 60 };

const boxStyle = { height: 40, width: 40, borderRadius: 8, margin: 10 };

export const ColourPalette = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { spaceRow } = store;

  return (
    <div style={containerStyle}>
      {Object.values(palette).map((item, i) => {
        return (
          <div
            key={i}
            style={{ ...boxStyle, backgroundColor: item }}
            onClick={() => {
              dispatch({ type: actionTypes.SET_SHOW_PALETTE, payload: false });
              dispatch({
                type: actionTypes.SET_SPACE_ROW,
                payload: { ...spaceRow, colour: item },
              });
            }}
          ></div>
        );
      })}
      <Button
        onClick={() =>
          dispatch({ type: actionTypes.SET_SHOW_PALETTE, payload: false })
        }
      >
        Cancel
      </Button>
    </div>
  );
};
