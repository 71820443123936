import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { Schedule } from "./bookings/Schedule";
import { SelectSpace } from "./bookings/SelectSpace";
import { ShoppingCart } from "../../components/ShoppingCart";
import { Checkout } from "../../components/Checkout";

export const Bookings = () => {
  const [store] = useContext(StoreContext);
  const { showCheckout } = store;
  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          AlignItems: "center",
        }}
      >
        {showCheckout ? null : <SelectSpace />}
        {showCheckout ? null : <ShoppingCart />}
      </div>
      {showCheckout ? <Checkout /> : <Schedule />}
    </div>
  );
};
