import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { StoreContext } from "../../store/Store";
import { getSite } from "../../api/api";
import { SignupForm } from "./SignupForm";
import { Confirmation } from "./Confirmation";

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

export const Signup = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState();
  const [user, setUser] = useState();
  const [store] = useContext(StoreContext);
  const { candidateConfirmed } = store;

  useEffect(() => {
    const user = searchParams.get("user");
    if (user) {
      const asynfunction = async () => {
        const ret = await getSite(user);
        if (ret.statusCode === 200 && "body" in ret) {
          const body = JSON.parse(ret.body);
          setUser({ user: user, name: body.name });
        } else {
          setMessage(`Invalid user ${user}`);
        }
      };
      asynfunction();
    }
  }, [searchParams]);

  return (
    <div style={containerStyle}>
      {message ? (
        message
      ) : user ? (
        candidateConfirmed ? (
          <Confirmation />
        ) : (
          <SignupForm user={user} />
        )
      ) : null}
    </div>
  );
};
