export const ReserveSpace = () => {
  return true ? null : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "4px",
          paddingBottom: "4px",
          border: "1px solid black",
          borderRadius: "12px",
          color: "#ffffff",
          backgroundColor: "#000000",
        }}
      >
        3 Slots selected
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 20,
          paddingRight: 20,
          cursor: "pointer",
        }}
        title="Reserve space"
      >
        {/* <Button
          onClick={() => bookThisSpace(dispatch, actionTypes, id)}
          // style={{
          //   border: "1px solid  black",
          //   borderRadius: 8,
          //   backgroundColor: "brown",
          //   color: "white",
          //   width: "260px",
          // }}
        >
          RESERVE SPACE
        </Button> */}
      </div>
    </div>
  );
};
