import { useContext } from "react";
import Cart from "../images/svg/ShoppingCart";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";

export const ShoppingCart = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { config, newBookings } = store;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "4px",
          paddingBottom: "4px",
          border: "1px solid black",
          borderRadius: "12px",
          color: "#ffffff",
          backgroundColor: "#000000",
        }}
      >
        {`${newBookings.length} ${
          newBookings.length === 1
            ? config.translations.slotSelected
            : config.translations.slotsSelected
        }`}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 20,
          paddingRight: 20,
          cursor: "pointer",
        }}
        title="Checkout"
        onClick={() =>
          dispatch({ type: actionTypes.SET_SHOW_CHECKOUT, payload: true })
        }
      >
        <Cart width={30} height={30} />
      </div>
    </div>
  );
};
