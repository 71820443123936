import React, { useReducer, useMemo, createContext } from "react";
import { init_global, global_reducer } from "./globalReducer";

export const StoreContext = createContext(undefined);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(global_reducer, init_global);
  const store = useMemo(() => [state, dispatch], [state]);
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
