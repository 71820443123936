import { Schedule } from "./bookings/Schedule";
import { SelectSpace } from "./bookings/SelectSpace";
import { ReserveSpace } from "./bookings/ReserveSpace";

export const Bookings = () => {
  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          AlignItems: "center",
        }}
      >
        <SelectSpace />
        <ReserveSpace />
      </div>
      <Schedule />
    </div>
  );
};
