import { useContext, useRef, useEffect, useState } from "react";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { Thumbnails } from "./Thumbnails";
import { FullSize } from "./FullSize";
import { Caption } from "./Caption";

const containerStyle = {
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: "auto",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
};

export const ImageDisplay = () => {
  const [store] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const { spaceRow } = store;
  const [dimensions, setDimensions] = useState(null);
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const box = containerRef.current.getBoundingClientRect();
      setDimensions(box);
    }
  }, []);

  return (
    <div ref={containerRef} style={containerStyle}>
      {spaceRow.id in grefs.spaceImageCache.current &&
        Object.keys(grefs.spaceImageCache.current[spaceRow.id]).length >
          0 && (
          <>
            <Thumbnails dimensions={dimensions} />
            <div
              style={{
                display: "flex",
                // border: "1px solid blue",
                flexGrow: 1,
                flexShrink: 0,
                flexBasis: "auto",
                height: "100%",
                flexDirection: "column",
                boxSizing: "border-box",
              }}
            >
              <Caption />
              <FullSize />
            </div>
          </>
        )}
    </div>
  );
};
