import { useContext, useEffect, Fragment, useState } from "react";
import { StoreContext } from "../store/Store";
import { GlobalRefContext } from "../store/GlobalRefs";
import { Button, DialogActions } from "@mui/material";
import { actionTypes } from "../store/storeTypes";
import { listBookingAnon, postMySQLData } from "../api/api";
import { formatDateforMySQL, uniqueid } from "../components/utils";

const containerStyle = {
  height: "100%",
  width: "100%",
  backgroundColor: "#ffffff",
};
const tableContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: 40,
};
const parentStyle = {
  fontWeight: 600,
};
const childStyle = { fontSize: 12 };
export const Checkout = () => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const {
    auth,
    checkoutKeyedBookings,
    identity,
    newBookings,
    showCheckout,
    spacePrice,
  } = store;
  const [totalCost, setTotalCost] = useState();

  useEffect(() => {
    if (newBookings) {
      const keyedBookings = {};
      let totalCost = 0;
      newBookings.forEach((item) => {
        const cost = +spacePrice[item.space];
        totalCost += cost;
        if (item.space in keyedBookings) {
          keyedBookings[item.space].push({
            ...item,
            cost: cost,
          });
        } else {
          keyedBookings[item.space] = [{ ...item, cost: cost }];
        }
      });
      dispatch({
        type: actionTypes.SET_CHECKOUT_KEYED_BOOKINGS,
        payload: keyedBookings,
      });
      setTotalCost(totalCost);
    }
  }, [dispatch, newBookings, spacePrice]);

  const onPay = () => {
    const bookings = newBookings.map((item) => {
      return {
        site: item["user"],
        id: item["space"],
        eventId: uniqueid(),
        title: item["title"],
        start: formatDateforMySQL(item.start),
        end: formatDateforMySQL(item.end),
        user: item["id"],
        description: item["description"],
      };
    });

    const asyncfunction = async () => {
      const ret = await postMySQLData(auth, "booking", {
        bookings: bookings,
      });
      if (ret.statusCode === 201) {
        dispatch({
          type: actionTypes.SET_NEW_BOOKINGS,
          payload: [],
        });

        const resBookings = await listBookingAnon(
          auth,
          identity.user,
          identity.id
        );
        if (resBookings.statusCode === 200) {
          grefs.bookings.current = JSON.parse(resBookings.body).bookings;
          dispatch({
            type: actionTypes.SET_BOOKINGS_LOADED,
            payload: true,
          });
          dispatch({ type: actionTypes.SET_RELOAD, payload: true });
          dispatch({ type: actionTypes.SET_SHOW_CHECKOUT, payload: false });
        }
      } else {
        console.log("ret", ret);
      }
    };
    asyncfunction();
  };
  const onCancel = () => {
    dispatch({ type: actionTypes.SET_SHOW_CHECKOUT, payload: false });
  };

  const onRemove = (item) => {
    const filteredBookings = newBookings.filter((fitem) => {
      return fitem.event_id !== item.event_id;
    });
    dispatch({ type: actionTypes.SET_NEW_BOOKINGS, payload: filteredBookings });
  };
  return showCheckout && checkoutKeyedBookings && totalCost ? (
    <div style={containerStyle}>
      <DialogActions>
        <Button onClick={() => onPay()}>{`Pay R${totalCost}`}</Button>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </DialogActions>
      <div style={tableContainerStyle}>
        <table>
          <tbody>
            {Object.entries(checkoutKeyedBookings).map((entry, i) => {
              return (
                <Fragment key={`f${i}`}>
                  <tr>
                    <td colSpan={8} style={parentStyle}>
                      {entry[0]}
                    </td>
                  </tr>
                  {entry[1].map((item, ii) => {
                    return (
                      <tr style={childStyle} key={`c${ii}`}>
                        <td style={{ paddingLeft: 20, paddingRight: 20 }}>
                          From
                        </td>
                        <td>{formatDateforMySQL(item.start)}</td>
                        <td style={{ paddingLeft: 20, paddingRight: 20 }}>
                          to
                        </td>
                        <td>{formatDateforMySQL(item.end)}</td>
                        <td style={{ paddingLeft: 20, paddingRight: 20 }}>
                          at
                        </td>
                        <td>{`R${item.cost}`}</td>
                        <td>per hour</td>
                        <td>
                          <Button
                            style={{ padding: 0, fontSize: 12 }}
                            onClick={() => onRemove(item)}
                          >
                            remove
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};
