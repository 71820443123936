import { useState, useEffect, useContext } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";
import { GlobalRefContext } from "../store/GlobalRefs";
import { Button } from "@mui/material";
import {
  addDays,
  formatDateforMySQL,
  getStartOfWeekDate,
} from "../components/utils";

import { bookThisSpace, reserveSlot } from "./userspaces/functions";

export const Availability = () => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    bookingsLoaded,
    config,
    identity,
    newBookings,
    selectedDateAvailability,
    spaces,
  } = store;
  const grefs = useContext(GlobalRefContext);
  const [events, setEvents] = useState();

  useEffect(() => {
    grefs.selectedAvailabiltyDate.current = grefs.selectedScheduleDate.current;
    dispatch({
      type: actionTypes.SET_SELECTED_DATE_AVAILABILITY,
      payload: grefs.selectedScheduleDate.current,
    });
  }, [dispatch, grefs]);

  useEffect(() => {
    if (spaces && bookingsLoaded && selectedDateAvailability) {
      const spaceColours = spaces.reduce((a, b) => {
        return { ...a, [b.id]: b.colour };
      }, {});
      let events = [];
      const booked = {};
      grefs.bookings.current.forEach((item) => {
        item.bookings.forEach((booking) => {
          if (item.id in booked) {
            booked[item.id].push(formatDateforMySQL(new Date(booking.start)));
          } else {
            booked[item.id] = [formatDateforMySQL(new Date(booking.start))];
          }
        });
      });
      const rightNow = new Date();
      let ddate = getStartOfWeekDate(
        new Date(grefs.selectedAvailabiltyDate.current.getTime()),
        config.scheduler.week.weekStartOn
      );

      for (let i = 0; i < 7; i++) {
        const year = ddate.getFullYear();
        const month = ddate.getMonth();
        const day = ddate.getDate();
        for (
          let h = config.scheduler.week.startHour;
          h < config.scheduler.week.endHour;
          h++
        ) {
          const startDate = new Date(year, month, day, h);
          const endDate = new Date(year, month, day, h + 1);

          if (startDate >= rightNow) {
            spaces.forEach((item) => {
              events.push({
                title: item.id,
                description: item.description,
                color:
                  item.id in booked &&
                  booked[item.id].includes(formatDateforMySQL(startDate))
                    ? "#ffffff"
                    : spaceColours[item.id],
                start: startDate,
                end: endDate,
                event_id: `${item.id}_${i}_${h}`,
                subtitle: "",
                deletable: false,
                editable: false,
                draggable: false,
              });
            });
          }
        }
        ddate = addDays(ddate, 1);
      }
      setEvents(events);
    }
  }, [bookingsLoaded, config, grefs, selectedDateAvailability, spaces]);

  const onSelectedDateChange = (sdate) => {
    grefs.selectedAvailabiltyDate.current = sdate;
    dispatch({
      type: actionTypes.SET_SELECTED_DATE_AVAILABILITY,
      payload: addDays(sdate),
    });
  };

  return events && config ? (
    <Scheduler
      events={events}
      height={400}
      week={config.scheduler.week}
      // disableViewer={true}
      disableViewNavigator={true}
      onSelectedDateChange={onSelectedDateChange}
      selectedDate={grefs.selectedScheduleDate.current}
      eventRenderer={({ event, ...props }) => {
        return (
          <div
            style={{
              height: "100%",
              background: event.color,
            }}
            {...props}
          ></div>
        );
      }}
      viewerExtraComponent={(fields, event) => {
        return (
          <div>
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              <Button
                onClick={() => {
                  bookThisSpace(dispatch, actionTypes, event.title);
                  reserveSlot(
                    event.start,
                    event.end,
                    identity,
                    newBookings,
                    event.title,
                    grefs,
                    dispatch
                  );
                }}
                style={{
                  border: "1px solid  black",
                  borderRadius: 8,
                  backgroundColor: "brown",
                  color: "white",
                  width: "260px",
                }}
              >
                BOOK THIS SPACE
              </Button>
              <Button
                onClick={() => {
                  dispatch({
                    type: actionTypes.SET_SHOW_ALL_IMAGES,
                    payload: { active: true, id: event.title },
                  });
                  dispatch({
                    type: actionTypes.SET_SELECTED,
                    payload: "Spaces",
                  });
                }}
              >
                VIEW THIS SPACE
              </Button>
            </div>
            <p>{event.description || "Nothing..."}</p>
          </div>
        );
      }}
    />
  ) : null;
};
