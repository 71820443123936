import { useContext } from "react";
import { TextField, Button, DialogActions } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { editDynamoDBData, deleteDynamoDBData } from "../../api/api";

import { deepCopyStringify } from "../../components/utils";

const containerStyle = {
  width: "100%",
  //   padding: 20,
  //   marginLeft: -20,
};
const parentStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "start",
  gap: 10,
};

const editInputStyle = {
  display: "flex",
  justifyContent: "start",
  paddingTop: 20,
  paddingBottom: 10,
  gap: 10,
};

export const EditRow = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    auth,
    config,
    identity,
    spaceRow,
    spaces,
    showPalette,
    showSpaceImages,
  } = store;

  const onChange = (val, key) => {
    const newRow = { ...spaceRow, [key]: val };
    dispatch({ type: actionTypes.SET_SPACE_ROW, payload: newRow });
  };

  const validateRow = (key, newRow) => {
    if (spaceRow && key in spaceRow && (spaceRow[key] || spaceRow[key] === 0)) {
      newRow[key] = spaceRow[key];
      return true;
    } else {
      alert(`${key} is mandatory`);
      return false;
    }
  };
  const onSave = () => {
    const newRow = { user: identity.user };
    if (!validateRow("id", newRow)) return;
    if (!validateRow("address", newRow)) return;
    if (!validateRow("description", newRow)) return;
    if (!validateRow("colour", newRow)) return;
    if (!validateRow("cost", newRow)) return;
    //if (!validateRow("images", newRow)) return;
    newRow.images = spaceRow.images;

    //add to spaces data
    const newSpaces = spaces.filter((item) => !(item.id === newRow.id));
    newSpaces.push(newRow);
    dispatch({
      type: actionTypes.SET_SPACES,
      payload: newSpaces,
    });

    // SET_SPACES
    const strNewRow = deepCopyStringify(newRow);
    const asyncfunction = async () => {
      await editDynamoDBData(auth, "space", strNewRow);
    };
    asyncfunction();
  };

  const onClear = () => {
    dispatch({ type: actionTypes.SET_SPACE_ROW, payload: null });
  };
  const onDelete = () => {
    if (spaceRow && "id" in spaceRow && spaceRow.id) {
      if (window.confirm(`Delete ${spaceRow.id}`)) {
        const newSpaces = spaces.filter((item) => !(item.id === spaceRow.id));
        dispatch({
          type: actionTypes.SET_SPACES,
          payload: newSpaces,
        });

        dispatch({ type: actionTypes.SET_SPACE_ROW, payload: null });
        const asyncfunction = async () => {
          await deleteDynamoDBData(auth, "space", identity.user, spaceRow.id);
        };
        asyncfunction();
      }
    } else {
      alert(`enter a space to delete`);
    }
  };

  return defaultWidth ? (
    <div style={containerStyle}>
      <div style={parentStyle}>
        <DialogActions>
          <Button onClick={() => onClear()}>Clear</Button>
          <Button onClick={() => onSave()}>Save</Button>
          <Button onClick={() => onDelete()}>Delete</Button>
        </DialogActions>
      </div>
      <div style={editInputStyle}>
        <TextField
          style={{ width: `${defaultWidth.id}px` }}
          label="Name"
          value={spaceRow && spaceRow.id ? spaceRow.id : ""}
          onChange={(e) => onChange(e.target.value, "id")}
        />
        <TextField
          style={{ width: `${defaultWidth.address}px` }}
          label="Address"
          value={spaceRow && spaceRow.address ? spaceRow.address : ""}
          onChange={(e) => onChange(e.target.value, "address")}
        />
        <TextField
          style={{ width: `${defaultWidth.cost}px` }}
          label={config.translations.cost}
          type="number"
          value={spaceRow && spaceRow.cost ? spaceRow.cost : ""}
          onChange={(e) => onChange(e.target.value, "cost")}
        />

        <Button
          style={{
            border: `2px solid ${spaceRow ? spaceRow.colour : "black"}`,
          }}
          onClick={() =>
            dispatch({
              type: actionTypes.SET_SHOW_PALETTE,
              payload: !showPalette,
            })
          }
        >
          Colour
        </Button>
        <Button
          onClick={() =>
            dispatch({
              type: actionTypes.SET_SHOW_SPACE_IMAGES,
              payload: !showSpaceImages,
            })
          }
        >
          {showSpaceImages ? "Spaces" : "Images"}
        </Button>
      </div>
      <TextField
        style={{
          flexGrow: 1,
          flexBasis: "auto",
          flexShrink: 0,
          paddingLeft: 10,
          paddingRight:10,
          display: "flex",
        }}
        label="Description"
        value={spaceRow && spaceRow.description ? spaceRow.description : ""}
        onChange={(e) => onChange(e.target.value, "description")}
      />
    </div>
  ) : null;
};
