const divStyle = {
  height: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const Result = ({ status }) => {
  if (status === "success") {
    return <div style={divStyle}> ✅</div>;
  } else if (status === "fail") {
    return <div style={divStyle}> ❌</div>;
  } else if (status === "uploading") {
    return <div style={divStyle}> ⏳</div>;
  } else {
    return <div style={divStyle}></div>;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};