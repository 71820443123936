import React from "react";
// import { StoreContext } from "../store/Store";

export const Reports = () => {
  // const [store] = useContext(StoreContext);
  // const {  } = store;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        boxSizing: "border-box",
        padding: 40,
      }}
    >
      <div>
        <h1>Reports</h1>
      </div>
    </div>
  );
};
