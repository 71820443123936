import { useState, useContext } from "react";
import dayjs from "dayjs";
import {
  TextField,
  Button,
  InputLabel,
  Select,
  DialogActions,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { postMySQLData } from "../../api/api";

export const CustomEditor = ({ scheduler }) => {
  const [store] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const { auth, identity, customers, selectedSpace } = store;

  const event = scheduler.edited;

  // Make your own form/state
  const [state, setState] = useState({
    title: event?.title || "",
    description: event?.description || "",
    start: dayjs(event ? event?.start : scheduler.state.start.value),
    end: dayjs(event ? event?.end : scheduler.state.end.value),
  });
  const [error, setError] = useState("");

  const handleChange = (value, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleSubmit = async () => {
    // Your own validation
    if (!state.title) {
      return setError("Required");
    }

    try {
      scheduler.loading(true);
      const spaceEvents = grefs.bookings.current.find(
        (item) => item.id === selectedSpace
      );

      const events =
        spaceEvents && spaceEvents.bookings ? spaceEvents.bookings : [];

      const list = events.map((item) => item.event_id.split("_")[1]);
      const nextEventId = list && list.length > 0 ? Math.max(...list) : 0;
      const eventId = event ? event.event_id : "event_" + (nextEventId + 1);

      const newItem = {
        user: state.user,
        event_id: eventId,
        title: state.title,
        start: state.start.$d,
        end: state.end.$d,
        description: state.description,
      };

      const updatedBooking = events
        .filter((item) => item.event_id !== eventId)
        .concat([newItem]);

      const payload = grefs.bookings.current
        .filter((item) => item.id !== selectedSpace)
        .concat([
          {
            user: identity.user,
            id: selectedSpace,
            bookings: updatedBooking,
          },
        ]);

      const ret = await postMySQLData(auth, "booking", {
        user: identity.user,
        id: selectedSpace,
        bookings: [newItem],
      });
      console.log("ret", ret);

      grefs.bookings.current = payload;

      /**Simulate remote data saving */
      const added_updated_event = await new Promise((res) => {
        /**
         * Make sure the event have 4 mandatory fields
         * event_id: string|number
         * title: string
         * start: Date|string
         * end: Date|string
         */

        res(newItem);
      });

      scheduler.onConfirm(added_updated_event, event ? "edit" : "create");
      scheduler.close();
    } finally {
      scheduler.loading(false);
    }
  };
  return customers ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="customeEditor" style={{ padding: "1rem" }}>
        <h1>Booking</h1>
        <FormControl fullWidth error={!!error}>
          <InputLabel id="demo-simple-select-label">Customer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.title}
            label="Customer"
            onMouseOver={() => setError("")}
            onChange={(e, child) => {
              handleChange(e.target.value, "title");
              handleChange(child.props.id, "description");
            }}
          >
            {customers.map((item, i) => {
              return (
                <MenuItem
                  key={i}
                  value={item.firstName + " " + item.surname}
                  id={item.id}
                >
                  {item.firstName + " " + item.surname}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
        <div style={{ marginTop: 10 }}>
          <TextField label="email" value={state.description} fullWidth />
        </div>
        <div style={{ display: "flex", marginTop: 10, gap: 20 }}>
          <DateTimePicker
            label="Start"
            value={state.start}
            onChange={(newValue) => handleChange(newValue, "start")}
          />
          <DateTimePicker
            label="end"
            value={state.end}
            onChange={(newValue) => handleChange(newValue, "end")}
          />
        </div>
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Cancel</Button>
        <Button onClick={handleSubmit}>Confirm</Button>
      </DialogActions>
    </LocalizationProvider>
  ) : null;
};
