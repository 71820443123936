import { useContext } from "react";
import { Button } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { bookThisSpace } from "./functions";

const containerStyle = {
  width: "100%",
  display: "flex",
  paddingTop: 10,
  boxSizing: "border-box",
};
export const SpaceHeader = ({ spaceRow }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { config } = store;

  const onExit = () => {
    dispatch({
      type: actionTypes.SET_SHOW_ALL_IMAGES,
      payload: { active: false, id: "" },
    });
  };
  return spaceRow ? (
    <div style={containerStyle} className="space-header">
      <div
        style={{
          width: "100%",
          paddingLeft: 20,
          display: "flex",
        }}
      >
        <h3 style={{ whiteSpace: "nowrap" }}>{spaceRow.id}</h3>
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {spaceRow.description}
        </div>
      </div>
      <div>
        <Button
          onClick={() => bookThisSpace(dispatch, actionTypes, spaceRow.id)}
          style={{
            border: "1px solid  black",
            borderRadius: 8,
            backgroundColor: "brown",
            color: "white",
            // width: "260px",
          }}
        >
          {`${config.translations.bookThisSpaceFor} ${config.translations.currencySymbol}${spaceRow.cost} ${config.translations.costPerHour}`}
        </Button>
      </div>
      <Button style={{ width: "60px" }} onClick={() => onExit()}>
        Exit
      </Button>
    </div>
  ) : null;
};
