import React, { useContext, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { UserSpaces } from "./UserSpaces";
// import { Bookings } from "./Bookings"
import { Bookings } from "./userspaces/Bookings";
import { Availability } from "./Availability";
import { TabButtons } from "../components/TabButtons";
import { GlobalRefContext } from "../store/GlobalRefs";
import { actionTypes } from "../store/storeTypes";
import {
  getDynamoDBData,
  getDynamoDBConfig,
  listBookingAnon,
} from "../api/api";
import { deepMergeObjects } from "../components/utils";

const list = ["Spaces", "Bookings", "Availability"];

export const User = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { selected, auth, identity, loggedIn } = store;

  const grefs = useContext(GlobalRefContext);

  useEffect(() => {
    if (loggedIn.status) {
      const asyncFunction = async () => {
        const resConfig = await getDynamoDBConfig(auth, identity.user);
        const defConfig = await getDynamoDBConfig(auth, "default");
        if (resConfig.statusCode === 200 && defConfig.statusCode === 200) {
          dispatch({
            type: actionTypes.SET_CONFIG,
            payload: deepMergeObjects(
              resConfig.body.Item ? resConfig.body.Item : {},
              defConfig.body.Item ? defConfig.body.Item : {}
            ),
          });
        }
        const resSpaces = await getDynamoDBData(auth, "spaces", identity.user);
        if (resSpaces.statusCode === 200) {
          dispatch({
            type: actionTypes.SET_SPACES,
            payload: resSpaces.body,
          });
          const spacePrice = resSpaces.body.reduce((a, b) => {
            return { ...a, [b.id]: b.cost };
          }, {});

          dispatch({
            type: actionTypes.SET_SPACE_PRICE,
            payload: spacePrice,
          });
        }
        const resCustomers = await getDynamoDBData(
          auth,
          "customer",
          identity.user,
          identity.id
        );

        if (resCustomers.statusCode === 200) {
          dispatch({
            type: actionTypes.SET_CUSTOMERS,
            payload: resCustomers.body,
          });
        }
        const resBookings = await listBookingAnon(
          auth,
          identity.user,
          identity.id
        );

        if (resBookings.statusCode === 200) {
          grefs.bookings.current = JSON.parse(resBookings.body).bookings;
          dispatch({
            type: actionTypes.SET_BOOKINGS_LOADED,
            payload: true,
          });
        }
      };
      asyncFunction();
    }
  }, [auth, dispatch, grefs, identity, loggedIn]);

  // const location = useLocation();

  return loggedIn.status && auth ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <TabButtons list={list} />
      <div
        style={{
          width: "100%",
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "auto",
        }}
      >
        {selected === "Spaces" && <UserSpaces />}
        {selected === "Bookings" && <Bookings />}
        {selected === "Availability" && <Availability />}
      </div>
    </div>
  ) : null;
};
