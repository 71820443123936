import React, { useRef, createContext } from "react";
export const GlobalRefContext = createContext(undefined);

export const GlobalRefProvider = ({ children }) => {
  const bookings = useRef([]);
  const spaceImageCache = useRef({})
  const selectedScheduleDate = useRef(new Date())
  const selectedAvailabiltyDate = useRef(new Date())

  const refs = {
    bookings,
    spaceImageCache,
    selectedScheduleDate,
    selectedAvailabiltyDate
  };

  return refs ? (
    <GlobalRefContext.Provider value={refs}>
      {children}
    </GlobalRefContext.Provider>
  ) : null;
};
