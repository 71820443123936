import { useContext } from "react";
import { TextField, Button, DialogActions } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { validateEmail } from "../utils";
import { addCandidate } from "../../api/api";

const containerStyle = { display: "flex", flexDirection: "column", gap: 10 };

export const SignupForm = ({ user }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { candidate } = store;

  const onChange = (val, key) => {
    const newRow = { ...candidate, [key]: val };
    dispatch({ type: actionTypes.SET_CANDIDATE, payload: newRow });
  };

  const onSubmit = () => {
    const newRow = { user: user.user };
    if (!validateRow("firstName", newRow)) return;
    if (!validateRow("surname", newRow)) return;
    if (!validateRow("id", newRow)) return;

    const asyncfunction = async () => {
      const ret = await addCandidate(newRow);
      if (ret.statusCode === 201) {
        dispatch({ type: actionTypes.SET_CANDIDATE_CONFIRMED, payload: true });
      }
    };
    asyncfunction();
  };
  const validateRow = (key, newRow) => {
    if (
      candidate &&
      key in candidate &&
      (candidate[key] || candidate[key] === 0)
    ) {
      if (key === "id") {
        if (!validateEmail(candidate[key])) {
          alert("Invalid Email");
          return false;
        }
      }
      newRow[key] = candidate[key];
      return true;
    } else {
      alert(`${key} is mandatory`);
      return false;
    }
  };

  return (
    <div style={containerStyle}>
      <h1>{`Signup to rent space with ${user.name}`} </h1>
      <TextField
        //   style={{ width: `${defaultWidth.id}px` }}
        label="Name"
        value={candidate && candidate.firstName ? candidate.firstName : ""}
        onChange={(e) => onChange(e.target.value, "firstName")}
      />
      <TextField
        //   style={{ width: `${defaultWidth.id}px` }}
        label="Surname"
        value={candidate && candidate.surname ? candidate.surname : ""}
        onChange={(e) => onChange(e.target.value, "surname")}
      />
      <TextField
        //   style={{ width: `${defaultWidth.id}px` }}
        label="Email"
        value={candidate && candidate.id ? candidate.id : ""}
        onChange={(e) => onChange(e.target.value, "id")}
      />
      <DialogActions>
        <Button onClick={() => onSubmit()}>Submit</Button>
      </DialogActions>
    </div>
  );
};
