import { useContext, useState, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { JsonEditor } from "json-edit-react";
import { actionTypes } from "../store/storeTypes";
import { Button, DialogActions } from "@mui/material";
import { editDynamoDBConfig, getDynamoDBConfig } from "../api/api";

const parentStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "start",
  gap: 10,
};

export const Config = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, config, identity } = store;
  const [editableConfig, setEditableConfig] = useState();

  useEffect(() => {
    if (config) {
      const newConfig = Object.assign({}, config);
      delete newConfig.user;
      setEditableConfig(newConfig);
    }
  }, [config, setEditableConfig]);

  const setJsonData = (payload) => {
    dispatch({ type: actionTypes.SET_CONFIG, payload: payload });
  };

  const onSave = () => {
    const payload = Object.assign({}, editableConfig);
    payload.user = identity.user;
    editDynamoDBConfig(auth, payload);
  };
  const onReset = () => {
    const asyncFunction = async () => {
      const resConfig = await getDynamoDBConfig(auth, identity.user);
      if (resConfig.statusCode === 200) {
        dispatch({
          type: actionTypes.SET_CONFIG,
          payload: resConfig.body.Item,
        });
      }
    };
    asyncFunction();
  };


  return editableConfig ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        boxSizing: "border-box",
        padding: 40,
      }}
    >
      <div>
        <div style={parentStyle}>
          <DialogActions>
            <Button onClick={() => onReset()}>Reset</Button>
            <Button onClick={() => onSave()}>Save</Button>
          </DialogActions>
        </div>
        <JsonEditor
          data={editableConfig}
          setData={setJsonData}
          enableClipboard={false}
        />
      </div>
    </div>
  ) : null;
};
