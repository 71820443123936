export const actionTypes = {
  SET_AUTH: "SET_AUTH",
  SET_BOOKINGS_LOADED: "SET_BOOKINGS_LOADED",
  SET_CANDIDATE: "SET_CANDIDATE",
  SET_CANDIDATE_CONFIRMED: "SET_CANDIDATE_CONFIRMED",
  SET_CHECKOUT_KEYED_BOOKINGS: "SET_CHECKOUT_KEYED_BOOKINGS",
  SET_CONFIG: "SET_CONFIG",
  SET_CREDENTIALS: "SET_CREDENTIALS",
  SET_CUSTOMERS: "SET_CUSTOMERS",
  SET_CUSTOMER_ROW: "SET_CUSTOMER_ROW",
  SET_DELETE_IMAGE_HAPPENED: "SET_DELETE_IMAGE_HAPPENED",
  SET_FORGOT_PASSWORD: "SET_FORGOT_PASSWORD",
  SET_IDENTITY: "SET_IDENTITY",
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_NEW_BOOKINGS: "SET_NEW_BOOKINGS",
  SET_SPACES: "SET_SPACES",
  SET_SPACE_PRICE: "SET_SPACE_PRICE",
  SET_SPACE_ROW: "SET_SPACE_ROW",
  SET_SELECTED: "SET_SELECTED",
  SET_SELECTED_DATE_AVAILABILITY: "SET_SELECTED_DATE_AVAILABILITY",
  SET_SELECTED_SPACE: "SET_SELECTED_SPACE",
  SET_SELECTED_SPACE_IMAGE: "SET_SELECTED_SPACE_IMAGE",
  SET_SHOW_ALL_IMAGES: "SET_SHOW_ALL_IMAGES",
  SET_SHOW_CHECKOUT: "SET_SHOW_CHECKOUT",
  SET_SHOW_PALETTE: "SET_SHOW_PALETTE",
  SET_SHOW_SPACE_IMAGES: "SET_SHOW_SPACE_IMAGES",
  SET_SPACE_IMAGE_CACHE_LOADED: "SET_SPACE_IMAGE_CACHE_LOADED",
  SET_STATUS: "SET_STATUS",
  SET_REQUIRE_NEW_PASSWORD: "SET_REQUIRE_NEW_PASSWORD",
  SET_RELOAD: "SET_RELOAD",
};
