// const Result = ({ status }) => {
//   if (status === "success") {
//     return <> ✅</>;
//   } else if (status === "fail") {
//     return <> ❌</>;
//   } else if (status === "uploading") {
//     return <> ⏳</>;
//   } else {
//     return null;
//   }
// };

const labelStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: 12,
  fontWeight: 400,
  color: "black",
  backgroundColor: "#fffff",
  borderRadius: 8,
  paddingLeft: 8,
  paddingRight: 8,
  border: "1px solid black",
  // height: "100%",
  cursor: "pointer",
};


export const Choose = ({ handleFileChange }) => {
  return (
    <div style={labelStyle}>
      <label htmlFor={`file_x`}>Choose image</label>
      <input
        id={`file_x`}
        type="file"
        style={{ display: "none", cursor: "pointer" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
