import { useEffect, useContext } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { actionTypes } from "../../../store/storeTypes";
import { StoreContext } from "../../../store/Store";
import { GlobalRefContext } from "../../../store/GlobalRefs";
import { formatDateforSchedulePosition } from "../../../components/utils";
import { reserveSlot } from "../functions";

export const Schedule = () => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    bookingsLoaded,
    config,
    identity,
    newBookings,
    reload,
    selectedSpace,
  } = store;
  // const [reload, setReload] = useState(false);
  const grefs = useContext(GlobalRefContext);

  useEffect(() => {
    if (selectedSpace) {
      // setReload(true);
      dispatch({ type: actionTypes.SET_RELOAD, payload: true });
    }
  }, [dispatch, selectedSpace]);

  useEffect(() => {
    if (reload) {
      // setReload(false);
      dispatch({ type: actionTypes.SET_RELOAD, payload: false });
    }
  }, [dispatch, reload]);

  const fetchRemote = async (query) => {
    return new Promise((res) => {
      if (grefs.bookings.current) {
        const properyEvents = grefs.bookings.current.find(
          (item) => item.id === selectedSpace
        );
        const events = properyEvents
          ? properyEvents.bookings.map((item) => {
              return {
                ...item,
                start: new Date(item.start),
                end: new Date(item.end),
              };
            })
          : [];

        res(
          events.concat(
            newBookings.filter((item) => item.space === selectedSpace)
          )
        );
      } else {
        res([]);
      }
    });
  };

  const onEventClick = (event) => {
    const filteredBookings = newBookings.filter((item) => {
      return item.event_id !== event.event_id;
    });
    grefs.selectedScheduleDate.current = new Date(
      formatDateforSchedulePosition(event.start)
    );
    dispatch({
      type: actionTypes.SET_NEW_BOOKINGS,
      payload: filteredBookings,
    });
    dispatch({ type: actionTypes.SET_RELOAD, payload: true });
  };

  return reload ? null : selectedSpace && bookingsLoaded && config ? (
    <Scheduler
      height={400}
      week={config.scheduler.week}
      //editable={false}
      disableViewer={true}
      getRemoteEvents={fetchRemote}
      onEventClick={onEventClick}
      onCellClick={(start, end) =>
        reserveSlot(
          start,
          end,
          identity,
          newBookings,
          selectedSpace,
          grefs,
          dispatch
        )
      }
      selectedDate={grefs.selectedScheduleDate.current}
    />
  ) : null;
};
