const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 40,
};

export const Confirmation = ({ user }) => {
  return (
    <div style={containerStyle}>
      <h1>{`Your request for rental space has been recieved and we will contact you shortly`}</h1>
    </div>
  );
};
