import { useState, useEffect, useContext } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { CustomEditor } from "./CustomEditor";
// import { EVENTS } from "./Events";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { deleteBooking } from "../../api/api";

export const Schedule = () => {
  const [store] = useContext(StoreContext);
  const { auth, bookingsLoaded, config, identity, selectedSpace } = store;
  const [reload, setReload] = useState(false);
  const grefs = useContext(GlobalRefContext);

  useEffect(() => {
    if (selectedSpace) {
      setReload(true);
    }
  }, [selectedSpace]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  const fetchRemote = async (query) => {
    return new Promise((res) => {
      if (grefs.bookings.current && grefs.bookings.current.length > 0) {
        const properyEvents = grefs.bookings.current.find(
          (item) => item.id === selectedSpace
        );
        const events = properyEvents
          ? properyEvents.bookings.map((item) => {
              return {
                ...item,
                start: new Date(item.start),
                end: new Date(item.end),
              };
            })
          : [];
        res(events);
      } else {
        res([]);
      }
    });
  };

  const handleDelete = async (deletedId) => {
    const spaceEvents = grefs.bookings.current.find(
      (item) => item.id === selectedSpace
    );

    const events =
      spaceEvents && spaceEvents.bookings ? spaceEvents.bookings : [];

    const updatedBooking = events.filter((item) => item.event_id !== deletedId);

    const payload = grefs.bookings.current
      .filter((item) => item.id !== selectedSpace)
      .concat([
        {
          user: identity.user,
          id: selectedSpace,
          bookings: updatedBooking,
        },
      ]);

    const ret = await deleteBooking(
      auth,
      identity.user,
      selectedSpace,
      deletedId
    );
    console.log("ret", ret);

    grefs.bookings.current = payload;
    return new Promise((res, rej) => {
      res(deletedId);
    });
  };

  return reload ? null : selectedSpace && bookingsLoaded && config ? (
    <Scheduler
      height={400}
      week={config.scheduler.week}
      getRemoteEvents={fetchRemote}
      onDelete={handleDelete}
      customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
    />
  ) : null;
};
