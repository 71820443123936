import { actionTypes } from "../../store/storeTypes";
import { formatDateforSchedulePosition } from "../../components/utils";

export const bookThisSpace = (dispatch, actionTypes, selectedSpce) => {
  dispatch({
    type: actionTypes.SET_SELECTED_SPACE,
    payload: selectedSpce,
  });

  dispatch({ type: actionTypes.SET_SELECTED, payload: "Bookings" });
};
export const reserveSlot = (
  start,
  end,
  identity,
  newBookings,
  selectedSpace,
  grefs,
  dispatch
) => {
  const newBooking = {
    user: identity.user,
    id: identity.id,
    description:
      identity.role === "user"
        ? identity.name
        : `${identity.firstName} ${identity.surname}`,
    start: start,
    end: end,
    title:
      identity.role === "user"
        ? identity.name
        : `${identity.firstName} ${identity.surname}`,
    event_id: `newEvent_${newBookings.length + 1}`,
    color: "purple",
    space: selectedSpace,
    editable: false,
    deletable: false,
    draggable: false,
  };
  grefs.selectedScheduleDate.current = new Date(
    formatDateforSchedulePosition(start)
  );
  dispatch({
    type: actionTypes.SET_NEW_BOOKINGS,
    payload: newBookings.concat([newBooking]),
  });
  dispatch({ type: actionTypes.SET_RELOAD, payload: true });
};
